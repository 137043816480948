import React from "react";
import Layout from "../components/layout";
import AboutHero from "../components/about-hero";
import About from "../components/about";
import GitHubStats from "../components/github-stats";
import "../styles/pages/about.scss";

const AboutPage = () => (
    <Layout>
        <AboutHero></AboutHero>
        <About></About>
        <GitHubStats></GitHubStats>
    </Layout>
)

export default AboutPage
