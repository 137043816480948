import React from "react";
import Link from "gatsby-link";
import "../styles/components/about.modules.scss";

const AboutSection = () => (
        <section id="about" className="about-layout">
            <div className="inner">
                <h1>I'm a full-stack developer from in London, UK.</h1>

                <div className="cols">
                    <h3>Recent position and experience.</h3>

                    <p>Recently worked at <a title="Collider : Connected Brand Experiences agency" href="https://wearecollider.com/" target="_blank" rel="noopener noreferrer">Collider</a> a Connected Brand Experiences agency as a <strong>Technology Lead/Senior Full-Stack Developer</strong> for 6 years, during this time I helped with the delivery of great high-quality digital solutions for their clients.</p>

                    <p>From ideation, concept and build bringing to life impeccable front-end and tinkering server-side coding on a wide range of projects for a large range of clients such as <strong>Caffè Nero</strong>, <strong>Yoplait</strong>, <strong>Lloyds Register</strong>, <strong>Epson</strong>, <strong>Sharp</strong>, <strong>ICAP/Nex</strong>, <strong>Propertons</strong>, <strong>Leap/Quidcycle</strong>, <strong>AstraZeneca</strong>, <strong>Lyxor ETF</strong> and <strong>BT</strong>.</p>

                    Visit <a title="Collider : Connected Brand Experiences agency" href="https://wearecollider.com/about/" target="_blank" rel="noopener noreferrer">Collider website</a> for more information.

                    <h3>Origins</h3>

                    <p>Originally from Portugal where I studied visual arts,<br/>to the United Kingdom where I have been in the industry since 2008.</p><p>It has been here that I have honed my skills with various development roles in agencies and brand names throughout London.</p>

                    <h3>I do what I Love and<br/>I Love what I do.</h3>

                    <p>This includes a lot more than development!</p>
                    
                    <p>Since very young that am very close to arts, and I am passionate about <a title="Check out my Behance profile" href="https://www.behance.net/mdesignsuk" target="_blank" rel="noopener noreferrer">UI/UX, Digital Design, Illustration</a> and <a title="Check out my Photography account on Instagram" href="https://www.instagram.com/m.duarte_/" target="_blank" rel="noopener noreferrer">Photography</a>!</p>

                    <p>But not just arts, I am also into mountain biking, drone flying/building, gaming, electronics, cooking, and many more. I like to keep my sel busy with new and interesting things.</p>

                    <p>Have I sparked the interest into finding out more about me and my experience? Why not checking my profile on <a title="See Mario's profile on LinkedIn" href="https://www.linkedin.com/in/mario-duarte-developer/" target="_blank" rel="noopener noreferrer">LinkedIn</a>?</p>

                    <p>Want a more personal conversations, see how you can get in touch on the <Link to="/contact">Contact</Link> page.</p>
                </div>
            </div>
        </section>
)

export default AboutSection;