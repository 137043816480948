import React from "react";
import $ from "jquery/dist/jquery";
//import aboutHeroStyles from "../styles/components/about-hero.modules.scss";
import "../styles/components/about-hero.modules.scss";

class AboutHero extends React.Component {

    constructor(props) {
        super(props);
        this.state = {};
    }

    componentDidMount() {

    const particleContainer = $('#particles');
    const Nparticles = 50;
    let particles = [];

    function rand(min, max) {
        return Math.floor(Math.random() * (max - min)) + min;
    }

    function createParticle(i) {
        this.id = i;
        this.width = rand(1,20) + 'px';
        this.height = this.width;
        this.x = rand(10,90) + '%';
        this.delay = rand(1,30) + 's';
        this.duration = rand(10,30) + 's';
        this.html = '<span style=" width: '+this.width+'; height: '+this.height+'; left: '+this.x+'; animation-delay: '+this.duration+'; animation-duration: '+this.duration+'; "></span>';
    }

    while ( particles.length <= Nparticles ) {
        let Particle = new createParticle(particles.length);
        particles.push(Particle);
        particleContainer.append(Particle.html);
    }

    }

    render() {
        return(
            <div id="aboutHero" className="about-hero">

                <div className="background"></div>
                <div id="particles" className="particles"></div>
                <div className="foreground"></div>

                <div className="inner">
                    <h1>About me</h1>
                    <p>Find out all about me, from my experience to all the thing I love to do.<br/>PS: If you like the background animation drop a like on it on this <a title="The Dark - CSS animated background" href="https://codepen.io/MarioDesigns/full/OjQKyx" target="_blank" rel="noopener noreferrer">CodePen</a></p>
                </div>
                
            </div>
        );
    }

}

export default AboutHero