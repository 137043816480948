import React from "react";
//import $ from "jquery/dist/jquery";
import FaIcon from '../utils/icon.js';
import githubStyles from "../styles/components/github-stats.module.scss";

//const [githubData, setGithubData] = useState(0);
// useEffect(() => {
//     // get data from GitHub api
    
// }, []);

class GitHubStats extends React.Component{

    constructor(props) {
        super(props);
        this.state = {
            githubData: {} 
        };
    }

    componentDidMount () {
        fetch(`https://api.github.com/users/Mario-Duarte`)
        .then(response => response.json()) // parse JSON from request
        .then(resultData => {
            this.setState({githubData : resultData});
        }); // set data for the number of stars
    }

    render () {
        return (
            <section id="gitHubStats" className={githubStyles.githubStats} data-profile-url={this.state.githubData.html_url}>
                <div className={githubStyles.inner}>
    
                    <div className={githubStyles.avatar}><a href={this.state.githubData.html_url} target="_blank" rel="noopener noreferrer"><img src={this.state.githubData.avatar_url} alt={this.state.githubData.name} /></a></div>
                    <p><a href={this.state.githubData.html_url} target="_blank" rel="noopener noreferrer">{this.state.githubData.name} GitHub Stats</a></p>
    
                    <ul>
                        <li><h2>{this.state.githubData.public_repos}</h2><p>Public Repos</p></li>
                        <li><h2>{this.state.githubData.public_gists}</h2><p>Public Gists</p></li>
                        <li><h2>{this.state.githubData.followers}</h2><p>Followers</p></li>
                        <li><h2>{this.state.githubData.following}</h2><p>Following</p></li>
                    </ul>

                    <h2>My Pinned Repositories</h2>

                    <ul className={githubStyles.projects}>
                        <li>
                            <a href="https://github.com/Mario-Duarte/react-inView" target="_blank" rel="noopener noreferrer">
                                <h3>InView Callback</h3>
                                <p>Custom Hook that uses the new intersection observer API to determine when an element is in view and runs a custom callback on entry and exit.</p>
                                <p className={githubStyles.projectLanguage}><i className={githubStyles.projectJs}></i> Javascript/ReactJS</p>
                            </a>
                        </li>
                        <li>
                            <a href="https://github.com/Mario-Duarte/react-image-zooom" target="_blank" rel="noopener noreferrer">
                                <h3>React Image Zoom</h3>
                                <p>Simple React component that will allow users to zoom in on your images, perfect for product images and galleries!</p>
                                <p className={githubStyles.projectLanguage}><i className={githubStyles.projectJs}></i> Javascript/ReactJS</p>
                            </a>
                        </li>
                        <li>
                            <a href="https://mario-duarte.github.io/inView/" target="_blank" rel="noopener noreferrer">
                                <h3>inView</h3>
                                <p>jQuery plugin to detect when DOM elements are in view in the viewport</p>
                                <p className={githubStyles.projectLanguage}><i className={githubStyles.projectJs}></i> Javascript/jQuery</p>
                            </a>
                        </li>
                        <li>
                            <a href="https://mario-duarte.github.io/Smart-Sandbox/" target="_blank" rel="noopener noreferrer">
                                <h3>Smart-Sandbox</h3>
                                <p>Smart way of organizing your sandbox/root folder with dynamic search capabilities so no matter how big or how small your sandbox/root folder is you can easily find your projects.</p>
                                <p className={githubStyles.projectLanguage}><i className={githubStyles.projectPhp}></i> PHP</p>
                            </a>
                        </li>
                        <li>
                            <a href="https://github.com/Mario-Duarte/HTML5-Banner-Builder-Tool" target="_blank" rel="noopener noreferrer">
                                <h3>HTML5 Banner Build Tool</h3>
                                <p>A repo to be used when creating HTML5 Banners, this will auto generate all the base files and folders as create a dist .zip file of the banner ready to upload to you favorite add platform</p>
                                <p className={githubStyles.projectLanguage}><i className={githubStyles.projectJs}></i> Javascript/Node</p>
                            </a>
                        </li>
                        <li>
                            <a href="https://mario-duarte.github.io/image-zoom-plugin/" target="_blank" rel="noopener noreferrer">
                                <h3>Image Zoom Plugin</h3>
                                <p>Simple jQuery plugin that will allow users to zoom in your images, perfect for product images and galleries that is less than 1.5kb.</p>
                                <p className={githubStyles.projectLanguage}><i className={githubStyles.projectJs}></i> Javascript</p>
                            </a>
                        </li>
                    </ul>

                    <div className={githubStyles.logo}><a href="https://github.com/Mario-Duarte/" target="_blank" rel="noopener noreferrer"><FaIcon aria-label="github icon" type="fab" icon="github" /></a></div>
    
                </div>
            </section>
        )
    }

}

export default GitHubStats;